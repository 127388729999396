import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useUtils } from '@material-ui/pickers';
import format from 'date-fns/format';
import { Order } from 'deliveryPointStore';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '../../../components/Icons/CloseIcon';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            marginBottom: 0
        },
        closeButton: {
            padding: 0,
            paddingRight: theme.spacing(1),
            '&:hover': {
                backgroundColor: 'transparent'
            },
            [theme.breakpoints.up('lg')]: {
                paddingRight: 0
            }
        },
        smallText: {
            fontSize: theme.typography.pxToRem(14),
            color: theme.palette.text.secondary
        },
        caption: {
            fontSize: theme.typography.pxToRem(14),
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: 2.5
        },
        customerTable: {
            '& td': {
                borderBottom: 0,
                padding: `${theme.spacing(1)} 0`,
                color: theme.palette.text.secondary,
                '&:first-of-type': {
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.common.black
                }
            }
        },
        summaryRow: {
            '& td': {
                borderBottom: 0,
                fontWeight: theme.typography.fontWeightBold,
                color: theme.palette.common.black
            }
        },
        divider: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6)
        },
        itemsTable: {
            '& th:last-child, & td:last-child': {
                textAlign: 'right'
            }
        }
    })
);

type Props = {
    order: Order;
    closeDrawer: () => void;
};

const OrderInfo: React.FC<Props> = ({ order, closeDrawer }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { locale } = useUtils();

    return (
        <div>
            <Box display="flex" alignItems="middle" justifyContent="space-between" my={3}>
                <Typography variant="h3" className={classes.heading}>
                    {t('orderDetails.orderNo')} {order.orderNo}
                </Typography>
                <IconButton className={classes.closeButton} size="small" color="inherit" onClick={closeDrawer}>
                    <CloseIcon width="14px" height="14px" />
                </IconButton>
            </Box>

            <Divider light className={classes.divider} />

            <Typography variant="h4">{t('orderDetails.heading.customer')}</Typography>

            <Table className={classes.customerTable}>
                <TableBody>
                    <TableRow>
                        <TableCell>{t('orderDetails.customer.name')}</TableCell>
                        <TableCell>{order.customer.name}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t('orderDetails.customer.email')}</TableCell>
                        <TableCell>
                            <Link href={`mailto:${order.customer.email}`}>{order.customer.email}</Link>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{t('orderDetails.customer.phone')}</TableCell>
                        <TableCell>{order.customer.phone}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Divider light className={classes.divider} />

            <Typography variant="h4">{t('orderDetails.heading.deliveryInformation')}</Typography>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box fontWeight={700}>
                        <Typography className={classes.caption}>{t('orderDetails.delivery.date')}</Typography>
                    </Box>
                    <Typography className={classes.smallText}>
                        {format(new Date(order.deliveryDate), 'P', { locale })}
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography className={classes.caption}>{t('orderDetails.delivery.shippingProvider')}</Typography>
                    <Typography className={classes.smallText}>{order.shippingProvider}</Typography>
                </Grid>
            </Grid>

            <Divider light className={classes.divider} />

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography className={classes.caption}>{t('orderDetails.delivery.address')}</Typography>
                    <Typography className={classes.smallText}>{order.deliveryAddress}</Typography>
                </Grid>

                <Grid item xs={6}>
                    <Typography className={classes.caption}>{t('orderDetails.delivery.billingAddress')}</Typography>
                    <Typography className={classes.smallText}>{order.billingAddress}</Typography>
                </Grid>
            </Grid>

            {order.items && (
                <>
                    <Divider light className={classes.divider} />

                    <Typography variant="h4">{t('orderDetails.heading.orderSummary')}</Typography>

                    <Table className={classes.itemsTable}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('orderDetails.items.product')}</TableCell>
                                <TableCell>{t('orderDetails.items.articleNo')}</TableCell>
                                <TableCell>{t('orderDetails.items.quantity')}</TableCell>
                                <TableCell>{t('orderDetails.items.price')}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {order.items.map((row, idx) => (
                                <TableRow key={`product-row-${idx}`}>
                                    <TableCell scope="row">{row.name}</TableCell>
                                    <TableCell>{row.articleNo}</TableCell>
                                    <TableCell>{row.quantity}</TableCell>
                                    <TableCell>{row.price}</TableCell>
                                </TableRow>
                            ))}

                            {order.pickingCharge && (
                                <TableRow>
                                    <TableCell scope="row">{t('orderDetails.items.pickingCharge')}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{order.pickingCharge}</TableCell>
                                </TableRow>
                            )}

                            {order.shippingAmount && (
                                <TableRow>
                                    <TableCell scope="row">{t('orderDetails.items.shipping')}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{order.shippingAmount}</TableCell>
                                </TableRow>
                            )}

                            <TableRow className={classes.summaryRow}>
                                <TableCell scope="row">{t('orderDetails.items.total')}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{order.amount}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            )}
        </div>
    );
};

export default OrderInfo;
