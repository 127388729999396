import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Heading from './_internal/ProgressHeading';
import Label from './_internal/ProgressLabel';

type Props = {
    value: number;
    max: number;
    heading?: string;
    HeadingComponent?: typeof Heading;
    displayLabel?: boolean;
    LabelComponent?: typeof Label;
};

type StyleProps = {
    heading?: string;
    displayLabel?: boolean;
};

const useStyles = makeStyles(({ palette, typography, spacing }: Theme) =>
    createStyles({
        headingWrapper: {
            width: '100%'
        },
        progressBarWrapper: {
            width: ({ heading }: StyleProps) => (heading ? '100%' : 'auto')
        }
    })
);

const Progress: React.FC<Props> = ({
    value,
    max = 100,
    heading,
    HeadingComponent = Heading,
    displayLabel = true,
    LabelComponent = Label
}) => {
    const percentage = (value / max) * 100;
    const classes = useStyles({
        heading,
        displayLabel
    });

    return (
        <Box display="flex" alignItems="center" flexWrap="wrap">
            {heading && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={3}
                    className={classes.headingWrapper}
                >
                    <HeadingComponent heading={heading} />

                    {displayLabel && <LabelComponent max={max.toString()} value={value.toString()} />}
                </Box>
            )}

            {!heading && displayLabel && (
                <Box paddingRight={3}>
                    <LabelComponent max={max.toString()} value={value.toString()} />
                </Box>
            )}

            <Box className={classes.progressBarWrapper} flexGrow={1}>
                <LinearProgress variant="determinate" value={percentage} />
            </Box>
        </Box>
    );
};

export default Progress;
