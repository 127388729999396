import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ActionMenu from '../ActionMenu';

type Props = {
    fullName: string;
    handleLogout: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 94,
            borderBottom: '1px #dfdfdf solid',
            marginBottom: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(24)
            }
        },
        wrapper: {
            height: '100%',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                marginLeft: theme.spacing(16),
                marginRight: theme.spacing(16)
            }
        },
        logo: {
            width: 300,
            maxWidth: '50vw'
        },
        actionMenu: {
            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.18)',
            minWidth: 160,
            minHeight: 50,
            padding: `0 ${theme.spacing(8)}`
        }
    })
);

const Header: React.FC<Props> = ({ fullName, handleLogout }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.root}>
            <Box display="flex" className={classes.wrapper} alignItems="center" justifyContent="space-between">
                <Box>
                    <Link to="/">
                        <img className={classes.logo} src="/img/pbs-logo.svg" alt="Plantagen Booking System" />
                    </Link>
                </Box>

                <Box displayPrint="none">
                    <ActionMenu text={fullName}>
                        <MenuItem onClick={handleLogout} className={classes.actionMenu}>
                            {t('menu.user.logout')}
                        </MenuItem>
                    </ActionMenu>
                </Box>
            </Box>
        </Box>
    );
};

export default Header;
