import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { ArrowIcon, UserIcon } from '../Icons';

const useStyles = makeStyles(({ typography, spacing, breakpoints }) =>
    createStyles({
        menu: {
            fontSize: typography.button.fontSize,
            '& li': {
                fontSize: 'inherit',
                '&:hover': {
                    backgroundColor: 'inherit'
                }
            }
        },
        userButton: {
            textTransform: 'none',
            fontWeight: typography.fontWeightRegular,
            '&:hover': {
                backgroundColor: 'inherit'
            },
            '& .MuiButton-iconSizeMedium': {
                [breakpoints.down('xs')]: {
                    marginRight: 0,
                    marginLeft: 0
                }
            }
        },
        userIcon: {
            fontSize: '1.5rem !important'
        },
        userLabel: {
            [breakpoints.down('xs')]: {
                display: 'none'
            }
        },
        paper: {
            padding: `${spacing(0.5)} ${spacing(3)}`
        }
    })
);

type Props = {
    text: string;
};

const ActionMenu: React.FC<Props> = ({ text, children }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleMenu = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleMenu}
                className={classes.userButton}
                variant="text"
                startIcon={<UserIcon className={classes.userIcon} />}
                endIcon={<ArrowIcon direction="down" />}
            >
                <span className={classes.userLabel}>{text}</span>
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                placement="bottom-end"
                role={undefined}
                transition
                disablePortal
            >
                <Paper className={classes.paper}>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList className={classes.menu} autoFocusItem={open} disablePadding>
                            {React.Children.map(children, (child: any) =>
                                React.cloneElement(child, {
                                    onClick: () => {
                                        handleClose();
                                        child.props.onClick();
                                    }
                                })
                            )}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};

export default ActionMenu;
