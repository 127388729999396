import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import addDays from 'date-fns/addDays';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeek from 'date-fns/startOfWeek';
import React, { useState } from 'react';

import ArrowIcon from '../../../components/Icons/ArrowIcon';
import CalendarIcon from '../../../components/Icons/CalendarIcon';
import useSelectedDateState from '../../../hooks/useSelectedDateState';

const useStyles = makeStyles(({ palette, typography, spacing, breakpoints }: Theme) =>
    createStyles({
        button: {
            backgroundColor: palette.grey['100'],
            marginRight: spacing(2),
            '&$calendar': {
                marginLeft: spacing(2)
            }
        },
        calendar: {},
        day: {
            fontFamily: typography.fontFamily,
            width: 36,
            height: 36,
            fontSize: typography.caption.fontSize,
            margin: '0 2px',
            color: 'inherit',
            borderRadius: 0,
            '&:hover': {
                color: palette.primary.main,
                border: `solid 2px ${palette.primary.main}`,
                backgroundColor: 'transparent'
            }
        },
        daySelected: {
            fontWeight: 'bold',
            border: 'solid 2px #f10',
            backgroundColor: 'transparent',
            color: palette.primary.main,
            '&:hover': {
                backgroundColor: 'transparent'
            }
        },
        nonCurrentMonthDay: {
            color: palette.text.disabled,
            '&:hover': {
                border: 'solid 2px transparent',
                backgroundColor: 'transparent'
            }
        },
        highlightNonCurrentMonthDay: {
            color: palette.text.disabled,
            '&:hover': {
                color: palette.text.disabled
            }
        },
        firstHighlight: {
            extend: 'highlight',
            borderRadius: 0
        },
        endHighlight: {
            extend: 'highlight',
            borderRadius: 0
        }
    })
);

const CalendarTools: React.FC = () => {
    const [selectedDateState, setSelectedDateState] = useSelectedDateState();
    const [calendarIsOpen, setCalendarIsOpen] = useState(false);

    const anchorRef = React.useRef(null);
    const classes = useStyles();

    const handleCalendarOpen = () => {
        setCalendarIsOpen(!calendarIsOpen);
    };

    const handleDateChange = (date: Date | null) => {
        if (!date) return;
        setSelectedDateState((selectedDateState) => ({ ...selectedDateState, selectedDate: date }));
    };

    const renderWrappedWeekDay = (
        date: Date | null,
        selectedDate: Date | null,
        dayInCurrentMonth: boolean
    ): JSX.Element => {
        if (!date || !selectedDate) return <></>;

        const dateClone = new Date(date);
        const selectedDateClone = new Date(selectedDate);

        const start = startOfWeek(selectedDateClone);
        const end = endOfWeek(selectedDateClone);

        const dayIsBetween = isWithinInterval(dateClone, { start, end });
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);

        const wrapperClassName = clsx({
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay
        });

        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
            [classes.daySelected]: isSameDay(selectedDate, date)
        });

        return (
            <Box className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, 'd')} </span>
                </IconButton>
            </Box>
        );
    };

    return (
        <>
            <Box display="flex" displayPrint="none">
                <IconButton
                    onClick={() => {
                        handleDateChange(addDays(selectedDateState.selectedDate, -1));
                    }}
                    className={classes.button}
                    color="inherit"
                    size="small"
                >
                    <ArrowIcon direction="left" />
                </IconButton>
                <IconButton
                    onClick={() => {
                        handleDateChange(addDays(selectedDateState.selectedDate, 1));
                    }}
                    className={classes.button}
                    color="inherit"
                    size="small"
                >
                    <ArrowIcon direction="right" />
                </IconButton>
                <IconButton
                    ref={anchorRef}
                    onClick={handleCalendarOpen}
                    className={clsx(classes.button, classes.calendar)}
                    color="inherit"
                    size="small"
                >
                    <CalendarIcon />
                </IconButton>
            </Box>
            <DatePicker
                PopoverProps={{
                    anchorEl: anchorRef.current
                }}
                value={selectedDateState.selectedDate}
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                id="date-picker"
                label="Date picker"
                TextFieldComponent={() => null}
                open={calendarIsOpen}
                onOpen={() => setCalendarIsOpen(true)}
                onClose={() => setCalendarIsOpen(false)}
                onChange={handleDateChange}
                renderDay={renderWrappedWeekDay}
            />
        </>
    );
};

export default CalendarTools;
