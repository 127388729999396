import { createStyles, makeStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import clsx from 'clsx';
import React from 'react';

import ArrowIcon from './ArrowIcon';
import CalendarIcon from './CalendarIcon';
import CloseIcon from './CloseIcon';
import PrinterIcon from './PrinterIcon';
import UserIcon from './UserIcon';

export { ArrowIcon, UserIcon, PrinterIcon, CalendarIcon, CloseIcon };

const useSvgStyles = makeStyles(() =>
    createStyles({
        root: {
            width: (props: StyleProps) => `${props.size}`,
            height: (props: StyleProps) => `${props.size}`
        }
    })
);
type StyleProps = {
    size?: string;
};
export type IconWrapperProps = {
    /**
     * Size will override width and height property if set
     */
    size?: string;
} & SvgIconProps;

export const IconWrapper: React.FC<IconWrapperProps> = ({
    size,
    fill = 'currentColor',
    viewBox = '0 0 24 24',
    className,
    children,
    ...restProps
}) => {
    const classes = useSvgStyles({ size });

    return (
        <SvgIcon className={clsx(className, classes.root)} viewBox={viewBox} {...restProps}>
            {children}
        </SvgIcon>
    );
};

export default 1;
