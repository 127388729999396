import { useContext } from 'react';

import { SelectedDateContext } from '../contexts/selectedDateContext';

const useSelectedDateState = () => {
    const stateHandler = useContext(SelectedDateContext);

    return stateHandler;
};

export default useSelectedDateState;
