import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

type Props = {
    heading: string;
};

const useStyles = makeStyles(({ typography }: Theme) =>
    createStyles({
        heading: {
            fontWeight: typography.fontWeightBold
        }
    })
);

const ProgressHeading: React.FC<Props> = ({ heading }) => {
    const classes = useStyles();

    return (
        <Typography color="textPrimary" className={classes.heading}>
            {heading}
        </Typography>
    );
};

export default ProgressHeading;
