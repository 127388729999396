import { useContext } from 'react';

import { UserContext } from '../contexts/userContext';
import { UserContextType } from '../contexts/userContext';

const useUserState = (): UserContextType => {
    const stateHandler = useContext(UserContext);

    return stateHandler;
};

export default useUserState;
