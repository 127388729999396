import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) =>
    createStyles({
        actions: {
            [breakpoints.down('sm')]: {
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: spacing(4)
            }
        }
    })
);

const ActionBar: React.FC = ({ children }) => {
    const classes = useStyles();
    const [label, ...tools] = React.Children.toArray(children);

    return (
        <>
            {label}
            <Box display="flex" displayPrint="none" className={classes.actions}>
                {tools}
            </Box>
        </>
    );
};

export default ActionBar;
