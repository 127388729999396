import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo: {
            width: 300,
            maxWidth: '85vw',
            marginTop: theme.spacing(12)
        }
    })
);

const Logo: React.FC = () => {
    const classes = useStyles();

    return (
        <Link to="/">
            <img className={classes.logo} src="/img/pbs-logo.svg" alt="Plantagen Booking System" />
        </Link>
    );
};

export default Logo;
