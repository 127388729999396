import React from 'react';
import { useTranslation } from 'react-i18next';

import Progress from '../../../components/Progress';

type Props = {
    quota: number | null;
    usedQuota: number | null;
};

const QuotaStatus: React.FC<Props> = ({ quota, usedQuota }) => {
    const { t } = useTranslation();

    if (quota === null || usedQuota === null) {
        return null;
    }

    return <Progress max={quota} value={usedQuota} heading={t('viewStore.quotaLimit.status.label')} />;
};

export default QuotaStatus;
