import { OcapiToken } from 'authorization';
import { AuthorizationResponse } from 'authorization';
import axios from 'axios';
import { DeliveryPoints } from 'deliveryPoints';
import { Store } from 'deliveryPointStore';
import { QuotasResponse } from 'quotas';

const basePath = process.env.REACT_APP_API_BASE_PATH;
const language = process.env.REACT_APP_API_LANGUAGE;
const authSite = process.env.REACT_APP_API_AUTH_SITE;

const urls = {
    authenticate: (username: string, password: string) =>
        `${basePath}/on/demandware.store/${authSite}/${language}/PBS-Authenticate?username=${username}&userpwd=${password}`,
    deliveryPoints: (token: string) =>
        `${basePath}/on/demandware.store/${authSite}/${language}/PBS-DeliveryPoints?token=${token}`,
    deliveryPointStore: (storeId: string, date: string, token: string) =>
        `${basePath}/on/demandware.store/${authSite}/${language}/PBS-DeliveryPoint?storeId=${storeId}&date=${date}&token=${token}`,
    updateDeliveryBookingQuota: (token: string) =>
        `${basePath}/on/demandware.store/${authSite}/${language}/PBS-UpdateDeliveryBookingQuota?token=${token}`
};

export const authenticateRequest = async (username: string, password: string) => {
    const url = urls.authenticate(username, password);

    const response: AuthorizationResponse = {
        ocapiSsoToken: null,
        fullName: null,
        email: null,
        roles: [],
        statusCode: 200
    };

    try {
        const apiResponse = await axios.get(url);
        response.ocapiSsoToken = apiResponse.data.ocapiSsoToken;
        response.fullName = apiResponse.data.fullName;
        response.email = apiResponse.data.email;
        response.roles = apiResponse.data.roles;

        return response;
    } catch (err) {
        if (err.message === 'Network Error') {
            response.statusCode = 500;
        } else {
            response.statusCode = err.response.status;
        }

        return response;
    }
};

export const deliveryPointsRequest = async (token: OcapiToken) => {
    const url = urls.deliveryPoints(token.access_token);

    const response: DeliveryPoints = {
        deliveryPoints: [],
        statusCode: 200
    };

    try {
        const apiResponse = await axios.get(url);

        response.deliveryPoints = apiResponse.data.deliveryPoints;
        return response;
    } catch (err) {
        if (err.message === 'Network Error') {
            response.statusCode = 500;
        } else {
            response.statusCode = err.response.status;
        }

        return response;
    }
};

export const deliveryPointStoreRequest = async (storeId: string, date: string, token: OcapiToken) => {
    const url = urls.deliveryPointStore(storeId, date, token.access_token);

    let response: Store = {
        id: '0',
        name: '',
        quota: null,
        usedQuota: null,
        orders: [],
        statusCode: 200
    };

    try {
        const apiResponse = await axios.get(url);

        if (apiResponse.status !== 200) {
            response.statusCode = apiResponse.status;
            return response;
        }

        response = { ...response, ...apiResponse.data };
        return response;
    } catch (err) {
        if (err.message === 'Network Error') {
            response.statusCode = 500;
        } else {
            response.statusCode = err.response.status;
        }

        return response;
    }
};

export const updateDeliveryBookingQuotaRequest = async (
    storeId: string,
    date: string,
    quota: number,
    token: string
) => {
    const url = urls.updateDeliveryBookingQuota(token);

    let response: QuotasResponse = {
        quota: null,
        usedQuota: null,
        statusCode: 200
    };

    const data = new FormData();
    data.append('storeId', storeId);
    data.append('date', date);
    data.append('quota', quota.toString());

    try {
        const apiResponse = await axios.post(url, data);

        if (apiResponse.status !== 200) {
            response.statusCode = apiResponse.status;
            return response;
        }

        response = { ...response, ...apiResponse.data };
        return response;
    } catch (err) {
        if (err.message === 'Network Error') {
            response.statusCode = 500;
        } else {
            response.statusCode = err.response.status;
        }

        return response;
    }
};
