import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { hexToRgb, makeStyles, useTheme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(({ palette, zIndex }) => ({
    backdrop: {
        zIndex: zIndex.drawer + 1,
        color: palette.primary.main,
        backgroundColor: (props: { bgcolor?: string }) => props.bgcolor
    }
}));

type Props = {
    /**
     * Component visibility
     */
    open: boolean;
    invisible?: boolean;
};

const getBgColor = (hex: string) => {
    const rgba = hexToRgb(hex);
    const result = new RegExp(/(\d{1,3}(,){0,1}(( ){0,1})){1,}/).exec(rgba);

    if (result && result.length > 0) {
        return `rgba(${result[0]}, 0.5)`;
    }

    return null;
};

const Loader: React.FC<Props> = ({ open, invisible }) => {
    const theme = useTheme();
    const classes = useStyles({ bgcolor: getBgColor(theme.palette.primary.contrastText) });

    return (
        <Backdrop invisible={invisible} className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Loader;
