import React, { useState } from 'react';

type SelectedDateState = {
    selectedDate: Date;
};
type SelectedDateContextType = [SelectedDateState, React.Dispatch<React.SetStateAction<SelectedDateState>>];

const initState: SelectedDateState = {
    selectedDate: new Date()
};
const initContext: SelectedDateContextType = [initState, () => false];
const SelectedDateContext = React.createContext(initContext);

const SelectedDateContextProvider: React.FC = (props) => {
    const [selectedDateState, setSelectedDate] = useState<SelectedDateState>(initState);

    return (
        <SelectedDateContext.Provider value={[selectedDateState, setSelectedDate]}>
            {props.children}
        </SelectedDateContext.Provider>
    );
};

export { SelectedDateContext, SelectedDateContextProvider };
