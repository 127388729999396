import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer, { TableContainerProps } from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useUtils } from '@material-ui/pickers';
import format from 'date-fns/format';
import { Store } from 'deliveryPoints';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useSelectedDateState from '../../../hooks/useSelectedDateState';

import { ArrowIcon } from '../../../components/Icons';
import Progress from '../../../components/Progress';
import { DELIVERY_POINTS_LIST_PAGE_URL } from '../../../constants/routes';
import { createSlugFromString } from '../../../utils/string';

type Props = {
    items: Array<Store>;
} & TableContainerProps;

const useStyles = makeStyles(() =>
    createStyles({
        tableRow: {
            cursor: 'pointer'
        }
    })
);

const List: React.FC<Props> = ({ items, ...args }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { locale } = useUtils();
    const [, setSelectedDateState] = useSelectedDateState();

    const goToStorePage = (storeName: string, storeId: string) => {
        const slug = createSlugFromString(storeName);

        // Set todays date
        setSelectedDateState((selectedDateState) => ({ ...selectedDateState, selectedDate: new Date() }));

        history.push(`${DELIVERY_POINTS_LIST_PAGE_URL}/${slug}-${storeId}`);
    };

    return (
        <TableContainer component={Paper} {...args}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('listStores.list.name')}</TableCell>
                        <TableCell>{t('listStores.list.country')}</TableCell>
                        <TableCell>{t('listStores.list.ordersTotal')}</TableCell>
                        <TableCell>{t('listStores.list.ordersToday')}</TableCell>
                        <TableCell>{t('listStores.list.lastUpdated')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {items.map((row: Store, idx: number) => (
                        <TableRow
                            onClick={() => goToStorePage(row.name, row.id)}
                            className={classes.tableRow}
                            key={`row-${idx}`}
                        >
                            <TableCell component="th" scope="row">
                                <Typography variant="body1" color="textPrimary">
                                    {row.name}
                                </Typography>
                            </TableCell>
                            <TableCell>{row.country}</TableCell>
                            <TableCell>{row.ordersTotal}</TableCell>
                            <TableCell>
                                {row.usedQuota !== null && row.quota !== null && (
                                    <Box minWidth="100px">
                                        <Progress value={row.usedQuota} max={row.quota} />
                                    </Box>
                                )}
                            </TableCell>
                            <TableCell>
                                {row.lastUpdated !== null && format(new Date(row.lastUpdated), 'P', { locale })}
                            </TableCell>
                            <TableCell>
                                <Typography color="textPrimary" align="right">
                                    <ArrowIcon direction="right" />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default List;
