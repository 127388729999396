import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { IconWrapper, IconWrapperProps } from '../';

const useSvgStyles = makeStyles(() =>
    createStyles({
        root: {
            fill: 'transparent',
            stroke: 'currentColor'
        }
    })
);

const CalendarIcon: React.FC<IconWrapperProps> = (props) => {
    const classes = useSvgStyles();
    return (
        <IconWrapper className={classes.root} {...props}>
            <path
                d="M17.7273 6.33337H6.27273C5.56982 6.33337 5 6.93033 5 7.66671V17.6667C5 18.4031 5.56982 19 6.27273 19H17.7273C18.4302 19 19 18.4031 19 17.6667V7.66671C19 6.93033 18.4302 6.33337 17.7273 6.33337Z"
                strokeWidth="2"
                strokeLinecap="square"
            />
            <path d="M8.1817 5V7" strokeWidth="2" strokeLinecap="square" />
            <path d="M15.8181 5V7" strokeWidth="2" strokeLinecap="square" />
            <path d="M5 10H19" strokeWidth="2" strokeLinecap="square" />
        </IconWrapper>
    );
};

export default CalendarIcon;
