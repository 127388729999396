import { AuthorizationResponse } from 'authorization';
import React, { useEffect, useState } from 'react';

import { getPersistantState, setPersistantState } from '../utils/persistantState';

export type UserContextType = [AuthorizationResponse, React.Dispatch<React.SetStateAction<AuthorizationResponse>>];

const initState: AuthorizationResponse = getPersistantState() || {
    ocapiSsoToken: null,
    fullName: null,
    email: null,
    roles: [],
    statusCode: 200
};

const initContext: UserContextType = [initState, () => false];
const UserContext = React.createContext(initContext);

const UserContextProvider: React.FC = (props) => {
    const [state, setState] = useState<AuthorizationResponse>(initState);

    useEffect(() => {
        setPersistantState(state);
    }, [state]);

    return <UserContext.Provider value={[state, setState]}>{props.children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
