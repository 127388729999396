import { AuthorizationResponse } from 'authorization';

const persistantStateKey = 'PBS_STATE';
const enableEncryptedStorage = process.env.NODE_ENV === 'production';

const getPersistantState = (): AuthorizationResponse | null => {
    try {
        const jsonState = window.localStorage.getItem(persistantStateKey);

        if (!jsonState) {
            return null;
        }

        const decryptedJsonState = enableEncryptedStorage ? window.atob(jsonState) : jsonState;
        return JSON.parse(decryptedJsonState);
    } catch (e) {
        return null;
    }
};

const setPersistantState = (state: AuthorizationResponse): void => {
    try {
        const jsonState = JSON.stringify(state);
        const encryptedJsonState = enableEncryptedStorage ? window.btoa(jsonState) : jsonState;
        window.localStorage.setItem(persistantStateKey, encryptedJsonState);
    } catch (e) {
        console.error(e);
    }
};

export { getPersistantState, setPersistantState };
