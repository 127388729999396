import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AUTH_PAGE_URL, DELIVERY_POINTS_LIST_PAGE_URL } from './constants/routes';
import useUserState from './hooks/useUserState';

type CustomRouteProps = {
    Component: React.FC | React.ComponentClass;
} & RouteProps;

export const AuthRoute = ({ Component, ...rest }: CustomRouteProps) => {
    const [userState] = useUserState();

    if (userState.ocapiSsoToken?.access_token) {
        // Already logged in, redirect to DeliveryPointsPage
        return <Redirect to={{ pathname: DELIVERY_POINTS_LIST_PAGE_URL }} />;
    }

    return <Route {...rest} render={() => <Component />} />;
};

export const PrivateRoute = ({ Component, ...rest }: CustomRouteProps) => {
    const [userState] = useUserState();

    if (!userState.ocapiSsoToken?.access_token) {
        // Not logged in, redirect to AuthorizationPage
        return <Redirect to={{ pathname: AUTH_PAGE_URL }} />;
    }

    return <Route {...rest} render={() => <Component />} />;
};
