import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DeliveryPoints, Store } from 'deliveryPoints';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { deliveryPointsRequest } from '../../api/webApi';
import Footer from '../../components/Footer';
import Header from '../../containers/Header';
import useLoadingState from '../../hooks/useLoadingState';
import useUserState from '../../hooks/useUserState';
import List from './partials/List';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '73.8vh',
            [theme.breakpoints.down('sm')]: {
                minHeight: '79.8vh'
            }
        },
        divider: {
            marginTop: theme.spacing(6)
        }
    })
);

const Stores: React.FC = () => {
    const [userState, setUserState] = useUserState();
    const [, setLoadingState] = useLoadingState();
    const [deliveryPoints, setDeliveryPoints] = useState<Store[]>([]);

    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        const loadDeliveryPoints = async () => {
            if (!userState.ocapiSsoToken) {
                return;
            }

            setLoadingState((loadingState) => ({ ...loadingState, loading: true }));

            const apiDeliveryPoints: DeliveryPoints = await deliveryPointsRequest(userState.ocapiSsoToken);

            if (apiDeliveryPoints.statusCode !== 200) {
                // Log out user
                setUserState((userState) => ({
                    ...userState,
                    fullName: null,
                    email: null,
                    ocapiSsoToken: null,
                    roles: []
                }));

                history.push('/');
                return setLoadingState((loadingState) => ({ ...loadingState, loading: false }));
            }

            setDeliveryPoints(apiDeliveryPoints.deliveryPoints);
            setLoadingState((loadingState) => ({ ...loadingState, loading: false }));
        };

        loadDeliveryPoints();

        return () => {
            setDeliveryPoints([]);
        };
    }, [userState.ocapiSsoToken, history, setUserState, setLoadingState]);

    return (
        <>
            <Header />
            <Container maxWidth="lg" className={classes.container}>
                <Paper>
                    <Typography variant="h2" component="h1">
                        {t('listStores.title')}
                    </Typography>
                    <Box maxWidth="632px">
                        <Typography>{t('listStores.body')}</Typography>
                    </Box>
                    <Divider className={classes.divider} />
                    <Box pt={8}>
                        <List items={deliveryPoints} />
                    </Box>
                </Paper>
            </Container>
            <Footer />
        </>
    );
};

export default Stores;
