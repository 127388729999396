import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 94,
            marginTop: theme.spacing(16),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold
        },
        container: {
            height: '100%'
        },
        wrapper: {
            height: '100%'
        }
    })
);

const Footer: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.container}>
                <Box display="flex" alignItems="center" className={classes.wrapper}>
                    <p>{t('footer.copyright')}</p>
                </Box>
            </Container>
        </div>
    );
};

export default Footer;
