import React from 'react';

import { IconWrapper, IconWrapperProps } from '../';

const PrinterIcon: React.FC<IconWrapperProps> = (props) => {
    return (
        <IconWrapper {...props}>
            <rect x="7" y="4" width="10" height="3" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 9H19C19.6 9 20 9.4 20 10V16C20 16.6 19.6 17 19 17H17V19C17 19.6 16.6 20 16 20H8C7.4 20 7 19.6 7 19V17H5C4.4 17 4 16.6 4 16V10C4 9.4 4.4 9 5 9ZM9 18H15V13H9V18Z"
            />
        </IconWrapper>
    );
};

export default PrinterIcon;
