import './i18n';
import './theme/fonts/Plantagen-Bold.woff2';
import './theme/fonts/Plantagen-Regular.woff2';

import { ThemeProvider } from '@material-ui/core/';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import { AUTH_PAGE_URL, DELIVERY_POINTS_LIST_PAGE_URL, DELIVERY_POINTS_STORE_PAGE_URL } from './constants/routes';
import ContextProviders from './contextProviders';
import { AuthRoute, PrivateRoute } from './customRoute';
import AuthenticationPage from './pages/Authentication';
import DeliveryPointListStoresPage from './pages/ListStores';
import DeliveryPointStorePage from './pages/ViewStore';
import theme from './theme/custom';

const App: React.FC = () => (
    <ThemeProvider theme={theme}>
        <ContextProviders>
            <Router>
                <CssBaseline />
                <Switch>
                    <PrivateRoute
                        Component={DeliveryPointStorePage}
                        path={`/:lang?${DELIVERY_POINTS_STORE_PAGE_URL}`}
                    />
                    <PrivateRoute
                        Component={DeliveryPointListStoresPage}
                        path={`/:lang?${DELIVERY_POINTS_LIST_PAGE_URL}`}
                    />
                    <AuthRoute path={`/:lang?${AUTH_PAGE_URL}`} Component={AuthenticationPage} />
                </Switch>
            </Router>
        </ContextProviders>
    </ThemeProvider>
);

export default App;
