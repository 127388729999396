import { default as MuiBreadcrumbs } from '@material-ui/core/Breadcrumbs';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

import ArrowIcon from '../../../components/Icons/ArrowIcon';

const useStyles = makeStyles(({ typography, breakpoints }: Theme) =>
    createStyles({
        link: {
            textDecoration: 'none'
        },
        heading: {
            marginBottom: 0,
            [breakpoints.down('xs')]: {
                fontSize: '1.3rem'
            }
        },
        thinHeading: {
            marginBottom: 0,
            fontWeight: typography.fontWeightLight,
            [breakpoints.down('xs')]: {
                fontSize: '1.3rem'
            }
        },
        separatorIcon: {
            verticalAlign: 'middle',
            [breakpoints.down('xs')]: {
                margin: 0
            }
        }
    })
);

const BreadcrumbItem: React.FC<Breadcrumb> = ({ url, label }) => {
    const classes = useStyles();

    if (url) {
        return (
            <Link key={label} color="inherit" to={url || '/'} className={classes.link}>
                <Typography variant="h1" component="h1" color="textSecondary" className={classes.thinHeading}>
                    {label}
                </Typography>
            </Link>
        );
    }

    return (
        <Typography key={label} variant="h1" component="h2" color="textPrimary" className={classes.heading}>
            {label}
        </Typography>
    );
};

type Breadcrumb = {
    label: string;
    url?: string;
};

type Props = {
    items: Breadcrumb[];
};

const Breadcrumbs: React.FC<Props> = ({ items }) => {
    const classes = useStyles();

    if (items.length < 1) {
        return null;
    }

    return (
        <MuiBreadcrumbs
            separator={
                <Typography variant="h1" component="p" color="textSecondary" className={classes.heading}>
                    <ArrowIcon direction="right" className={classes.separatorIcon} />
                </Typography>
            }
            aria-label="breadcrumb"
        >
            {items.map((item, index) => (
                <BreadcrumbItem key={index} {...item} />
            ))}
        </MuiBreadcrumbs>
    );
};

export default Breadcrumbs;
