import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

type Props = {
    value: string;
    max?: string;
};

const useStyles = makeStyles({
    label: {
        fontSize: '0.8rem'
    }
});

const ProgressLabel: React.FC<Props> = ({ value, max }) => {
    const classes = useStyles();
    return (
        <Typography color="textSecondary" className={classes.label}>
            {value}
            {max && `/${max}`}
        </Typography>
    );
};

export default ProgressLabel;
