import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en, fi, no, sv } from './translations';

const resources = {
    en,
    fi,
    no,
    sv
};

// Get language from url
const wantedLanguage = window.location.pathname.substr(1, 2);
const acceptedLanguages = new RegExp('(sv|en|fi|no)');
const matchingLanguage = wantedLanguage.match(acceptedLanguages);
const languge = matchingLanguage && matchingLanguage[0] ? matchingLanguage[0] : 'en';

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    resources,
    lng: languge,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;
