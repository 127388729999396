import React from 'react';

import { LoadingContextProvider } from './contexts/loadingContext';
import { PickersProvider } from './contexts/pickersContext';
import { SelectedDateContextProvider } from './contexts/selectedDateContext';
import { UserContextProvider } from './contexts/userContext';

const ContextProviders: React.FC = ({ children }) => (
    <PickersProvider>
        <UserContextProvider>
            <SelectedDateContextProvider>
                <LoadingContextProvider>{children}</LoadingContextProvider>
            </SelectedDateContextProvider>
        </UserContextProvider>
    </PickersProvider>
);

export default ContextProviders;
