import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useUtils } from '@material-ui/pickers';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useSelectedDateState from '../../../hooks/useSelectedDateState';

const useStyles = makeStyles(({ spacing, breakpoints }: Theme) =>
    createStyles({
        title: {
            fontWeight: 'bold',
            flexGrow: 1,
            textTransform: 'capitalize',
            textAlign: 'right',
            marginRight: spacing(4),
            [breakpoints.down('sm')]: {
                marginTop: spacing(4),
                textAlign: 'left'
            }
        }
    })
);

const ActionBarLabel: React.FC<{ date: Date }> = ({ date }) => {
    const [selectedDateState] = useSelectedDateState();
    const classes = useStyles();
    const { t } = useTranslation();
    const { locale } = useUtils();

    return (
        <Typography className={classes.title}>
            {`${format(selectedDateState.selectedDate, 'PPPP', { locale })}${
                isToday(selectedDateState.selectedDate) ? ` (${t('viewStore.calendar.today')})` : ''
            }`}
        </Typography>
    );
};

export default ActionBarLabel;
