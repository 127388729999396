import React, { useState } from 'react';

import Loader from '../components/Loader';

type LoadingState = {
    loading: boolean;
};
type LoadingContextType = [LoadingState, React.Dispatch<React.SetStateAction<LoadingState>>];

const initState: LoadingState = {
    loading: false
};
const initContext: LoadingContextType = [initState, () => false];
const LoadingContext = React.createContext(initContext);

const LoadingContextProvider: React.FC = (props) => {
    const [loading, setLoading] = useState<LoadingState>(initState);

    return (
        <LoadingContext.Provider value={[loading, setLoading]}>
            {props.children}
            <Loader open={loading.loading} />
        </LoadingContext.Provider>
    );
};

export { LoadingContext, LoadingContextProvider };
