import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import format from 'date-fns/format';
import svLocale from 'date-fns/locale/sv';
import { Store } from 'deliveryPointStore';
import { QuotasResponse } from 'quotas';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { deliveryPointStoreRequest, updateDeliveryBookingQuotaRequest } from '../../api/webApi';
import Footer from '../../components/Footer';
import { DELIVERY_POINTS_LIST_PAGE_URL } from '../../constants/routes';
import Header from '../../containers/Header';
import useLoadingState from '../../hooks/useLoadingState';
import useSelectedDateState from '../../hooks/useSelectedDateState';
import useUserState from '../../hooks/useUserState';
import { default as StoreHeader } from './partials/Header';
import List from './partials/List';
import QuotaStatus from './partials/QuotaStatus';
import QuotaTools from './partials/QuotaTools';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: '73.8vh',
            [theme.breakpoints.down('sm')]: {
                minHeight: '79.8vh'
            }
        },
        divider: {
            marginTop: theme.spacing(6)
        }
    })
);

const ViewStore: React.FC = () => {
    const classes = useStyles();
    const { storeid } = useParams<{ storeid: any }>();
    const [userState, setUserState] = useUserState();
    const [selectedDateState] = useSelectedDateState();
    const [store, setStore] = useState<Store | null>();
    const [updateQuotaError, setUpdateQuotaError] = useState<string | null>(null);
    const [, setLoadingState] = useLoadingState();

    const history = useHistory();
    const { t } = useTranslation();

    const updateDeliveryBookingQuota = async (quota: number) => {
        if (!userState.ocapiSsoToken || !store) {
            return;
        }

        setLoadingState((loadingState) => ({ ...loadingState, loading: true }));

        const apiDeliveryBookingQuota: QuotasResponse = await updateDeliveryBookingQuotaRequest(
            store.id,
            format(selectedDateState.selectedDate, 'P', { locale: svLocale }),
            quota,
            userState.ocapiSsoToken.access_token
        );

        if (apiDeliveryBookingQuota.statusCode !== 200) {
            setUpdateQuotaError(t('viewStore.quotaLimit.updateError'));
            return setLoadingState((loadingState) => ({ ...loadingState, loading: false }));
        }

        setStore({
            ...store,
            quota: apiDeliveryBookingQuota.quota,
            usedQuota: apiDeliveryBookingQuota.usedQuota
        });
        setUpdateQuotaError(null);
        setLoadingState((loadingState) => ({ ...loadingState, loading: false }));
    };

    useEffect(() => {
        const loadDeliveryPointStore = async (selectedDate: Date) => {
            if (!userState.ocapiSsoToken) {
                return;
            }

            setLoadingState((loadingState) => ({ ...loadingState, loading: true }));

            const date = new Date(selectedDate).toISOString().split('T')[0];
            const apiDeliveryPointStore: Store = await deliveryPointStoreRequest(
                storeid,
                date,
                userState?.ocapiSsoToken
            );

            if (apiDeliveryPointStore.statusCode === 204) {
                // Redirect back to delivery points if store does not exist
                return history.push(DELIVERY_POINTS_LIST_PAGE_URL);
            }

            if (apiDeliveryPointStore.statusCode !== 200) {
                // Log out user
                setUserState((userState) => ({
                    ...userState,
                    fullName: null,
                    email: null,
                    ocapiSsoToken: null,
                    roles: []
                }));

                history.push('/');
                return setLoadingState((loadingState) => ({ ...loadingState, loading: false }));
            }

            setStore(apiDeliveryPointStore);
            setLoadingState((loadingState) => ({ ...loadingState, loading: false }));
        };

        loadDeliveryPointStore(selectedDateState.selectedDate);
    }, [selectedDateState, userState, setLoadingState, storeid, setUserState, history]);

    if (!store) {
        return null;
    }

    return (
        <>
            <Header />
            <Container maxWidth="lg" className={classes.container}>
                <StoreHeader storeName={store.name} />
                <Box marginTop={4} />
                <Divider />
                <Box marginY={8} />
                <QuotaTools
                    updateDeliveryBookingQuota={updateDeliveryBookingQuota}
                    updateQuotaError={updateQuotaError}
                    quota={store.quota}
                    usedQuota={store.usedQuota}
                />
                <Box marginY={8} />
                <QuotaStatus quota={store.quota} usedQuota={store.usedQuota} />
                <Box marginY={8} />
                <List orders={store.orders} />
            </Container>
            <Footer />
        </>
    );
};

export default ViewStore;
