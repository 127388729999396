import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PrinterIcon from '../../../components/Icons/PrinterIcon';
import { DELIVERY_POINTS_LIST_PAGE_URL } from '../../../constants/routes';
import ActionBar from './ActionBar';
import ActionBarLabel from './ActionBarLabel';
import Breadcrumbs from './Breadcrumbs';
import Calendar from './Calendar';

const useStyles = makeStyles(({ palette, typography, spacing, breakpoints }: Theme) =>
    createStyles({
        root: {
            justifyContent: 'space-between',
            alignItems: 'center',
            [breakpoints.down('sm')]: {
                flexDirection: 'column',
                alignItems: 'stretch'
            }
        },
        print: {
            marginRight: 0,
            '&:hover': {
                backgroundColor: 'transparent !important'
            }
        },
        separator: {
            backgroundColor: palette.grey['100'],
            width: '1px',
            height: '16px',
            marginRight: spacing(4),
            marginLeft: spacing(4)
        }
    })
);

type Props = {
    storeName: string;
};

const Header: React.FC<Props> = ({ storeName }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const breadcrumbs = [
        {
            label: t('viewStore.breadcrumb.deliveryPoints'),
            url: DELIVERY_POINTS_LIST_PAGE_URL
        },
        {
            label: storeName
        }
    ];

    return (
        <>
            <Toolbar className={classes.root}>
                <Breadcrumbs items={breadcrumbs} />
                <ActionBar>
                    <ActionBarLabel date={new Date()} />
                    <Calendar />
                    <Box className={classes.separator}></Box>
                    <IconButton onClick={() => window.print()} className={classes.print} color="inherit" size="small">
                        <PrinterIcon />
                    </IconButton>
                </ActionBar>
            </Toolbar>
        </>
    );
};

export default Header;
