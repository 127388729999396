import './fonts/font.css';

import { createMuiTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';

export const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            head: {
                fontSize: '0.6875rem',
                fontWeight: 'bold',
                color: '#999999',
                paddingTop: '6px',
                paddingBottom: '6px',
                whiteSpace: 'nowrap'
            },
            body: {
                paddingTop: '27px',
                paddingBottom: '27px',
                color: '#777777',
                fontSize: '0.875rem'
            }
        },
        MuiButtonBase: {},
        MuiTextField: {},
        MuiOutlinedInput: {
            input: {
                padding: '10px'
            },
            notchedOutline: {
                borderWidth: '2px'
            }
        },
        MuiInput: {
            underline: {
                padding: '0.25em 0.5em',
                border: '2px solid #eee',
                '&:before': {
                    borderBottom: '0px'
                },
                '&:after': {
                    bottom: '-2px',
                    borderBottom: '2px solid #000'
                },
                '&.Mui-focused': {
                    '&:after': {
                        bottom: '-2px',
                        transform: 'scaleX(1.01)',
                        borderBottom: '2px solid #000'
                    }
                },
                '&:hover:not(.Mui-disabled):before': {
                    bottom: '-2px',
                    transform: 'scaleX(1.01)'
                }
            }
        },
        MuiFormLabel: {
            root: {
                color: '#000',
                fontWeight: 'bold',
                marginTop: 16,
                marginBottom: 8
            }
        },
        MuiTableHead: {
            root: {
                textTransform: 'uppercase',
                backgroundColor: '#f7f7f7',
                color: '#999'
            }
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#DFDFDF'
            }
        },
        MuiPickersBasePicker: {
            pickerView: {
                border: 'solid 1px #eee'
            }
        },
        MuiPickersCalendarHeader: {
            iconButton: {
                '&:hover': { backgroundColor: 'transparent' }
            },
            dayLabel: {
                color: 'inherit',
                fontSize: '0.875rem'
            }
        },
        MuiIconButton: {
            root: {
                borderRadius: 0
            }
        }
    },
    shadows: Array(25).fill('none') as Shadows,
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
        MuiTextField: {}
    },
    spacing: (factor) => `${0.25 * factor}rem`,
    palette: {
        background: {
            default: '#fff'
        },
        primary: {
            main: '#f10'
        },
        text: {
            secondary: '#777777'
        }
    },
    shape: {
        borderRadius: 0
    },
    typography: {
        fontFamily: 'Plantagen',
        h1: {
            fontSize: '1.75rem',
            fontWeight: 'bold',
            marginBottom: '1em'
        },
        h2: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginBottom: '1em'
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            marginBottom: '1em'
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 'bold',
            marginBottom: '1em'
        },
        button: {
            fontSize: '1rem',
            fontWeight: 'bold',
            lineHeight: 2.5,
            textTransform: 'none'
        }
    }
});

export default theme;
