import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette, typography, spacing }: Theme) =>
    createStyles({
        root: {
            border: `solid 1px ${palette.divider}`
        },
        badge: {
            backgroundColor: palette.grey[100],
            fontWeight: typography.fontWeightBold,
            fontSize: '1.75rem'
        },
        paragraph: {
            maxWidth: 632
        },
        quotaLabel: {
            fontWeight: typography.fontWeightBold,
            marginBottom: spacing(4)
        }
    })
);

type Props = {
    quota: number | null;
    usedQuota: number | null;
    updateQuotaError: string | null;
    updateDeliveryBookingQuota: (quota: number) => void;
};

const QuotaTools: React.FC<Props> = ({ quota, usedQuota, updateQuotaError, updateDeliveryBookingQuota }) => {
    const [editMode, setEditMode] = useState(false);
    const [formQuota, setFormQuota] = useState(quota);

    const handleCancel = () => {
        setFormQuota(quota);
        setEditMode(false);
    };

    const updateQuota = () => {
        if (formQuota === null) {
            return;
        }

        updateDeliveryBookingQuota(formQuota);
        setEditMode(false);
    };

    useEffect(() => {
        // Initially set local state from prop
        setFormQuota(quota);
    }, [quota]);

    useEffect(() => {
        // Prevent below 0 inputs
        if (!formQuota || formQuota < 0) {
            setFormQuota(0);
        }
    }, [formQuota]);

    useEffect(() => {
        if (updateQuotaError) {
            setFormQuota(quota);
        }
    }, [updateQuotaError, quota]);

    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box className={classes.root} displayPrint="none">
            <Box padding={6}>
                {!editMode && (
                    <Grid container>
                        <Grid item>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                marginRight={6}
                                width={90}
                                height={90}
                                className={classes.badge}
                            >
                                {formQuota || 0}
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="h4" component="h2">
                                {t('viewStore.quotaLimit.heading')}
                            </Typography>
                            <Typography className={classes.paragraph}>
                                {t('viewStore.quotaLimit.description')}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {editMode && (
                    <Box>
                        <FormControl>
                            <Typography component="label" htmlFor="order-limit" className={classes.quotaLabel}>
                                {t('viewStore.quotaLimit.labelChangeLimit')}
                            </Typography>
                            <TextField
                                id="order-limit"
                                InputProps={{ inputProps: { min: 0 } }}
                                value={formQuota || 0}
                                onChange={(e) => setFormQuota(parseInt(e.currentTarget.value))}
                                type="number"
                            />
                        </FormControl>
                    </Box>
                )}
                <Box marginY={6} />
                <Divider />
                <Box marginY={6} />
                {updateQuotaError && (
                    <Box my={3}>
                        <Typography color="error">{updateQuotaError}</Typography>
                    </Box>
                )}
                <Box display="flex">
                    {!editMode && (
                        <Button variant="contained" color="primary" onClick={() => setEditMode(true)}>
                            {t('viewStore.quotaLimit.actionChangeLimit')}
                        </Button>
                    )}
                    {editMode && (
                        <>
                            <Button variant="contained" color="primary" onClick={updateQuota}>
                                {t('viewStore.quotaLimit.actionSaveLimit')}
                            </Button>
                            <Box marginLeft={4}>
                                <Button variant="contained" color="default" onClick={handleCancel}>
                                    {t('viewStore.quotaLimit.actionCancelLimit')}
                                </Button>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default QuotaTools;
