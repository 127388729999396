import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: {
            width: '100%',
            height: '100vh',
            objectFit: 'cover',
            objectPosition: 'bottom',
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'block'
            }
        }
    })
);

const SideBarImage: React.FC = () => {
    const classes = useStyles();

    return <img className={classes.image} src="/img/auth-background.jpg" alt="Background" />;
};

export default SideBarImage;
