import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { enGB, fi, nb, sv } from 'date-fns/locale';
import React from 'react';
import { useTranslation } from 'react-i18next';

type LocaleByLanguageType = {
    [key: string]: Locale;
};

const localeByLanguage: LocaleByLanguageType = {
    sv,
    en: enGB,
    fi,
    no: nb
};

export const PickersProvider: React.FC = ({ children }) => {
    const { i18n } = useTranslation();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeByLanguage[i18n.language]}>
            {children}
        </MuiPickersUtilsProvider>
    );
};

export default 1;
