import React from 'react';

import { IconWrapper, IconWrapperProps } from '../';

type Props = {
    direction?: 'right' | 'left' | 'down' | 'up';
} & IconWrapperProps;

const ArrowIcon: React.FC<Props> = ({ direction = 'right', ...restProps }) => {
    return (
        <IconWrapper {...restProps}>
            {direction === 'up' && <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />}
            {direction === 'down' && <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />}
            {direction === 'right' && <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />}
            {direction === 'left' && <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />}
        </IconWrapper>
    );
};

export default ArrowIcon;
