import { useContext } from 'react';

import { LoadingContext } from '../contexts/loadingContext';

const useLoadingState = () => {
    const stateHandler = useContext(LoadingContext);

    return stateHandler;
};

export default useLoadingState;
