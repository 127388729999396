import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer, { TableContainerProps } from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useUtils } from '@material-ui/pickers';
import format from 'date-fns/format';
import { Order } from 'deliveryPointStore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import OrderInfo from './OrderInfo';

type Props = {
    orders: Order[];
} & TableContainerProps;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableRow: {
            cursor: 'pointer'
        },
        drawerPaper: {
            minWidth: '90vw',
            padding: `${theme.spacing(6)} ${theme.spacing(2)}`,
            [theme.breakpoints.up('lg')]: {
                minWidth: 'auto',
                width: '40vw',
                padding: theme.spacing(6)
            }
        }
    })
);

const List: React.FC<Props> = ({ orders, ...args }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentOrder, setCurrentOrder] = useState<Order>(orders[0]);

    const showOrderInfo = (orderRow: Order) => {
        setCurrentOrder(orderRow);
        setDrawerOpen(true);
    };

    const { t } = useTranslation();
    const classes = useStyles();
    const { locale } = useUtils();

    if (orders.length <= 0) {
        return <p>{t('viewStore.noOrders')}</p>;
    }

    return (
        <>
            <TableContainer component={Paper} {...args}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('viewStore.list.orderNo')}</TableCell>
                            <TableCell>{t('viewStore.list.customer')}</TableCell>
                            <TableCell>{t('viewStore.list.productsTotal')}</TableCell>
                            <TableCell>{t('viewStore.list.shippingProvider')}</TableCell>
                            <TableCell>{t('viewStore.list.orderDate')}</TableCell>
                            <TableCell>{t('viewStore.list.orderAmount')}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {orders.map((row, idx) => (
                            <TableRow
                                className={classes.tableRow}
                                onClick={() => showOrderInfo(row)}
                                key={`row-${idx}`}
                            >
                                <TableCell scope="row">
                                    <Typography variant="body1" color="textPrimary">
                                        {row.orderNo}
                                    </Typography>
                                </TableCell>
                                <TableCell>{row.customer.name}</TableCell>
                                <TableCell>{row.productsTotal}</TableCell>
                                <TableCell>{row.shippingProvider}</TableCell>
                                <TableCell>{format(new Date(row.deliveryDate), 'P', { locale })}</TableCell>
                                <TableCell>{row.amount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <SwipeableDrawer
                PaperProps={{
                    className: classes.drawerPaper
                }}
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => {
                    setDrawerOpen(true);
                }}
            >
                <OrderInfo order={currentOrder} closeDrawer={() => setDrawerOpen(false)} />
            </SwipeableDrawer>
        </>
    );
};

export default List;
