import React from 'react';
import { useHistory } from 'react-router';

import HeaderComponent from '../components/Header';
import useUserState from '../hooks/useUserState';

const Header: React.FC = () => {
    const [userState, setUserState] = useUserState();
    const history = useHistory();

    const handleLogout = () => {
        setUserState((userState) => ({
            ...userState,
            fullName: null,
            email: null,
            ocapiSsoToken: null,
            roles: []
        }));
        history.push('/');
    };

    if (!userState.fullName) {
        return null;
    }

    return <HeaderComponent fullName={userState.fullName} handleLogout={handleLogout} />;
};

export default Header;
