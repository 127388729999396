import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    loggedIn: boolean;
    authError: string;
    handleLogin: (event: React.FormEvent, username: string, password: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ctaButton: {
            marginTop: theme.spacing(4)
        }
    })
);

const LoginForm: React.FC<Props> = ({ loggedIn, authError, handleLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const { t } = useTranslation();
    const classes = useStyles();

    if (loggedIn) {
        return null;
    }

    return (
        <Box pt={4}>
            <form onSubmit={(event) => handleLogin(event, username, password)}>
                <InputLabel htmlFor="email">{t('authorization.loginForm.username')}</InputLabel>
                <TextField
                    fullWidth
                    id="email"
                    value={username}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                    autoFocus
                    required
                />

                <InputLabel htmlFor="password">{t('authorization.loginForm.password')}</InputLabel>
                <TextField
                    fullWidth
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                    autoComplete="off"
                    required
                />

                {authError && (
                    <Box my={3}>
                        <Typography color="error">{authError}</Typography>
                    </Box>
                )}

                <Box mt={2}>
                    <Button className={classes.ctaButton} type="submit" fullWidth color="primary" variant="contained">
                        {t('authorization.loginForm.ctaLabel')}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default LoginForm;
