import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AuthorizationResponse } from 'authorization';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { authenticateRequest } from '../../api/webApi';
import { DELIVERY_POINTS_LIST_PAGE_URL } from '../../constants/routes';
import useLoadingState from '../../hooks/useLoadingState';
import useUserState from '../../hooks/useUserState';
import LoginForm from './partials/LoginForm';
import Logo from './partials/Logo';
import SideBarImage from './partials/SideBarImage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fullHeight: {
            height: '100vh'
        },
        horizontallyCenteredWrapper: {
            marginTop: theme.spacing(8),

            [theme.breakpoints.up('lg')]: {
                alignItems: 'center',
                height: '100vh',
                marginTop: 0
            }
        },
        horizontallyCenteredBox: {
            [theme.breakpoints.up('lg')]: {
                marginTop: '-30%'
            }
        },
        divider: {
            marginTop: theme.spacing(6)
        }
    })
);

const Authentication: React.FC = () => {
    const [userState, setUserState] = useUserState();
    const [, setLoadingState] = useLoadingState();
    const [authError, setAuthError] = useState('');

    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const handleLogin = async (event: React.FormEvent, username: string, password: string) => {
        event.preventDefault();

        setLoadingState((loadingState) => ({ ...loadingState, loading: true }));

        const response: AuthorizationResponse = await authenticateRequest(username, password);

        if (response.statusCode !== 200) {
            setAuthError(t(`authorization.error.${response.statusCode}`));
            setLoadingState((loadingState) => ({ ...loadingState, loading: false }));
            return;
        }

        setUserState(response);
        history.push(DELIVERY_POINTS_LIST_PAGE_URL);
    };

    return (
        <Grid container>
            <Grid item xs={12} lg={5}>
                <Container maxWidth="xs">
                    <Box className={classes.fullHeight} display="flex" flexDirection="column">
                        <Logo />

                        <Box display="flex" className={classes.horizontallyCenteredWrapper}>
                            <Box className={classes.horizontallyCenteredBox}>
                                <Typography variant="h3" component="h1">
                                    {t('authorization.heading')}
                                </Typography>

                                <Typography>{t('authorization.body')}</Typography>

                                <Divider className={classes.divider} />

                                <LoginForm
                                    loggedIn={!!userState.ocapiSsoToken?.access_token}
                                    authError={authError}
                                    handleLogin={handleLogin}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Grid>
            <Grid item xs={12} lg={7}>
                <SideBarImage />
            </Grid>
        </Grid>
    );
};

export default Authentication;
