import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import { IconWrapper, IconWrapperProps } from '../';

const useSvgStyles = makeStyles(() =>
    createStyles({
        root: {
            fill: 'transparent',
            stroke: 'currentColor'
        }
    })
);
const UserIcon: React.FC<IconWrapperProps> = ({ className, ...restProps }) => {
    const classes = useSvgStyles();

    return (
        <IconWrapper className={clsx(className, classes.root)} {...restProps}>
            <path
                d="M18 19.0001V17.4445C18 16.6194 17.6839 15.8281 17.1213 15.2446C16.5587 14.6612 15.7956 14.3334 15 14.3334H9C8.20435 14.3334 7.44129 14.6612 6.87868 15.2446C6.31607 15.8281 6 16.6194 6 17.4445V19.0001"
                stroke="#1C1C1C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.9999 11.2223C13.6568 11.2223 14.9999 9.82937 14.9999 8.11114C14.9999 6.3929 13.6568 5 11.9999 5C10.3431 5 8.99994 6.3929 8.99994 8.11114C8.99994 9.82937 10.3431 11.2223 11.9999 11.2223Z"
                stroke="#1C1C1C"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </IconWrapper>
    );
};

export default UserIcon;
